
import { IonRouterOutlet, IonTabBar, IonTabButton, IonTabs, IonLabel, IonPage } from '@ionic/vue';
import { library } from '@fortawesome/fontawesome-svg-core';
import { faHome, faBookmark } from '@fortawesome/pro-regular-svg-icons';
library.add(faHome, faBookmark);

export default {
	name: 'Tabs',
	components: { IonRouterOutlet, IonLabel, IonTabs, IonTabBar, IonTabButton, IonPage }
}
